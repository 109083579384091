import React from 'react';
import { Box, Heading, Text, Stack, Link, Center, AspectRatio, Image } from "@chakra-ui/react";
import { Link as GatsbyLink } from 'gatsby';
import BlogImage from '../../images/more/blog-img.png';
import AboutImage from '../../images/more/who-img.png';
import PlatformImage from '../../images/more/how-img.png';
import ContactImage from '../../images/more/contact-img.png';

export default function OtherPagesSection({ activePage }) {
  const WhoBox = <Link to="/about" as={GatsbyLink} variant="card" w="100%" overflow="hidden">
    <Box rounded="lg">
      <AspectRatio ratio={4/3}>
        <Image src={AboutImage} objectFit="cover" align="center" />
      </AspectRatio>
      <Box textAlign="center" p="1rem">
        <Heading size="lg">Who are we?</Heading>
        <Text>Find out more about our team</Text>
      </Box>
    </Box>
  </Link>

  const HowBox = <Link to="/product" as={GatsbyLink} variant="card" w="100%" overflow="hidden">
    <Box>
      <AspectRatio ratio={4/3}>
        <Image src={PlatformImage} objectFit="cover" align="center" />
      </AspectRatio>
      <Box textAlign="center" p="1rem">
        <Heading size="lg">How does it work?</Heading>
        <Text>All about the platform</Text>
      </Box>
    </Box>
  </Link>

  const BlogBox = <Link to="https://medium.com/makeripples" variant="card" as={GatsbyLink} w="100%" overflow="hidden">
    <Box>
      <AspectRatio ratio={4/3}>
        <Image src={BlogImage} objectFit="cover" align="center" />
      </AspectRatio>
      <Box textAlign="center" p="1rem">
        <Heading size="lg">Our blog</Heading>
        <Text>makeripples stories and news</Text>
      </Box>
    </Box>
  </Link>

  const ContactBox = <Link to="/contact" as={GatsbyLink} variant="card" w="100%" overflow="hidden">
    <Box>
      <AspectRatio ratio={4/3}>
        <Image src={ContactImage} objectFit="cover" align="center" />
      </AspectRatio>
      <Box textAlign="center" p="1rem">
        <Heading size="lg">Get in touch</Heading>
        <Text>We'd love to chat</Text>
      </Box>
    </Box>
  </Link>

  return <Box backgroundColor="white">
    <Box p={["2rem", null, "1rem", "2rem", "4rem"]}>
      <Center>
        <Stack w="100%" maxWidth="1600px" direction={["column", null, "row"]} justify="space-between" spacing={['2rem', null, "1rem", "2rem", '4rem']}>
          {activePage === '/about' ? ContactBox : WhoBox}
          {activePage === '/product' ? ContactBox : HowBox}
          {BlogBox}
        </Stack>
      </Center>
    </Box>
  </Box>
}