import React from 'react';
import { Box, Button, Heading, Text, Stack, Link } from "@chakra-ui/react";
import BackgroundImage from '../../images/backgrounds/bottom-BR.svg';
import BackgroundStars from '../../images/backgrounds/stars.png';
import { Link as GatsbyLink } from 'gatsby';

export default function MakeRipplesSection() {
  return <Box bgGradient="linear(to-b, #5B1258, #2F082E)" overflow="hidden" pos="relative">
    <Box pos="absolute" width="100%" height="100%" backgroundImage={`url(${BackgroundStars})`} backgroundRepeat="no-repeat" backgroundAttachment="fixed" backgroundSize="contain" />
    <Box minH="90vh" backgroundImage={`url(${BackgroundImage})`} backgroundPosition={["bottom center", "bottom right"]} backgroundSize={["90% auto", "50%", null, "auto 50%"]} backgroundRepeat="no-repeat" pt={150} pb={[300, null, 150]} zIndex={1}>
      <Box p={["2rem", "2rem", "4rem"]}>
        <Box w={['100%', '100%', '70%', '50%']}>
          <Stack spacing={8}>
            <Heading size="2xl" data-sal="slide-right" color="white" data-sal-duration="1000">Ready to make ripples?</Heading>
            <Text color="white" data-sal="slide-right" data-sal-duration="1000" data-sal-delay="500">Want to inspire your community?</Text>
            <Link to="https://app.makeripples.nz/account/signup?r=%2Forganiser" isExternal as={GatsbyLink} data-sal="slide-up" data-sal-duration="1000" data-sal-delay="1000" >
                <Button colorScheme="brand">
                  Get started
                </Button>
            </Link>
          </Stack>
        </Box>
      </Box>
    </Box>
  </Box>
}